import Ajax from '../../core/Ajax'
import api from 'common/api'

import { getTeam, getTeamInfo } from './selectors'
import throwAny from 'common/util/throwAny'

function success (type, payload, meta = {}) {
  return {
    type, payload, meta
  }
}

function failure (type, payload, meta = {}) {
  return {
    type, payload, error: true, meta
  }
}

export const USER_SEND_EMAIL_CONFIRM = 'USER_SEND_EMAIL_CONFIRM'
export const USER_SEND_EMAIL_CONFIRM_SUCCESS = 'USER_SEND_EMAIL_CONFIRM_SUCCESS'
export const USER_SEND_EMAIL_CONFIRM_FAILURE = 'USER_SEND_EMAIL_CONFIRM_FAILURE'

export function sendConfirmEmail (params = {}) {
  return (dispatch, getStore) => {
    dispatch({ type: USER_SEND_EMAIL_CONFIRM, meta: { params } })

    return Ajax
      .post('/users/sendconfirmemail', {
        data: params
      })
      .then(
        response => dispatch(success(USER_SEND_EMAIL_CONFIRM_SUCCESS, response, { params })),
        error => dispatch(failure(USER_SEND_EMAIL_CONFIRM_FAILURE, error, { params }))
      )
  }
}

export const USER_CHECK_EMAIL_CONFIRM_SUCCESS = 'USER_CHECK_EMAIL_CONFIRM_SUCCESS'
export const USER_CHECK_EMAIL_CONFIRM_FAILURE = 'USER_CHECK_EMAIL_CONFIRM_FAILURE'
export const USER_EMAIL_CONFIRMED = 'USER_EMAIL_CONFIRMED'

export function checkConfirmEmail () {
  return (dispatch, getStore) => Ajax
    .get('/users/checkconfirmemail')
    .then(
      response => {
        if (response.email_confirmed)
          dispatch(success(USER_EMAIL_CONFIRMED))

        return dispatch(success(USER_CHECK_EMAIL_CONFIRM_SUCCESS, response))
      },
      error => dispatch(failure(USER_CHECK_EMAIL_CONFIRM_FAILURE, error))
    )
}

export const FETCH_TEAM = 'USERS/FETCH_TEAM'
export const FETCH_TEAM_SUCCESS = 'USERS/FETCH_TEAM_SUCCESS'
export const FETCH_TEAM_FAILURE = 'USERS/FETCH_TEAM_FAILURE'

export const fetchTeam = () => (dispatch, getState) => {
  const team = getTeam(getState())

  if (team)
    return Promise.resolve(team)

  dispatch({ type: FETCH_TEAM })

  return api
    .get('/enterprises/v1/my/users')
    .then(res => {
      if (res.error) return dispatch({ type: FETCH_TEAM_FAILURE, error: res.error })

      const team = res.items.map(i => ({
        ...i.user,
        name: `${i.user.firstname} ${i.user.lastname}`
      }))

      dispatch({ type: FETCH_TEAM_SUCCESS, payload: team })

      return team
    })
}

export const fetchTeamOnceAtATime = () => (dispatch, getState) => {
  const { isLoading } = getTeamInfo(getState())
  if (isLoading) return Promise.resolve()
  return fetchTeam()(dispatch, getState)
}

export const FETCH_SETTINGS = 'USERS/FETCH_SETTINGS'
export const FETCH_SETTINGS_SUCCESS = 'USERS/FETCH_SETTINGS_SUCCESS'
export const FETCH_SETTINGS_FAILURE = 'USERS/FETCH_SETTINGS_FAILURE'

export const fetchSettings = () => async dispatch => {
  dispatch({ type: FETCH_SETTINGS })

  try {
    const response = await api.get('/users/v2/me/settings')
    if (response.error) throwAny(response.error)
    dispatch({ type: FETCH_SETTINGS_SUCCESS, payload: { value: response } })
    return response
  } catch (error) {
    return dispatch({ type: FETCH_SETTINGS_FAILURE, error: error })
  }
}

export const UPDATE_SETTINGS = 'USERS/UPDATE_SETTINGS'
export const UPDATE_SETTINGS_SUCCESS = 'USERS/UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_FAILURE = 'USERS/UPDATE_SETTINGS_FAILURE'

export const updateSettings = settings => async dispatch => {
  const meta = { updates: settings }
  dispatch({ type: UPDATE_SETTINGS, meta })

  const response = await api.put('/users/v2/me/settings', settings)

  if (response.error)
    return dispatch({ type: UPDATE_SETTINGS_FAILURE, error: response.error, meta })

  dispatch({ type: UPDATE_SETTINGS_SUCCESS, payload: { value: response }, meta })
  return response
}
